import React, { useState } from 'react';
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  TextareaAutosize,
  Tooltip,
  Box,
  LinearProgress,
  FormControl
} from '@mui/material';
import BugReportIcon from '@mui/icons-material/BugReport';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import axios from 'axios';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
const theme = createTheme({
    palette: {
      primary: {
        main: "#8B0000"
      },
      secondary: {
        main: "#000000"
      }
    }
  });
  const CreateTicket = ({ dynamicNumber }) => { // Accept dynamicNumber as a prop
    const [open, setOpen] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [problem, setProblem] = useState('');
  const [screenshot, setScreenshot] = useState(null);
  const [ticketId, setTicketId] = useState('');
  const [progress, setProgress] = useState(false);
  const [error, setError] = useState('');
  const [opens, setOpens] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloses = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpens(false);
  };
  
   
  const handleConfirmationClose = () => {
    setConfirmationOpen(false);
    setTicketId(''); // Reset ticket ID after showing confirmation
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setProgress(true);

    const formData = new FormData();
    formData.append('name', name);
    formData.append('email', email);
    formData.append('problem', problem);
    formData.append('status', "0");
    formData.append('dynamicNumber', dynamicNumber); // Add dynamic number to form data

    if (screenshot) {
      formData.append('screenshot', screenshot);
    }

    try {
      const response = await axios.post('https://edutech-backend-471f7cb69116.herokuapp.com/api/submit-issue', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 201) {
        setTicketId(response.data.ticketId);
        handleClose();
        setConfirmationOpen(true);
      } else {
        console.error('Error creating ticket:', response.data);
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setProgress(false);
    }
  };

  const handleScreenshotChange = (e) => {
    const file = e.target.files[0];
    const maxSizeBytes = 2 * 1024 * 1024; // 2MB in bytes
    if (file.size > maxSizeBytes) {
      setError('File size exceeds the limit of 2MB. Please choose a smaller file.');
      setScreenshot(null);
      return;
    }
    if (file) {
      const extension = file.name.split('.').pop();
      const newName = `edugate_issue_${Date.now()}.${extension}`;
      const renamedFile = new File([file], newName, { type: file.type });
      setScreenshot(renamedFile);
    }
  };
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloses}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );


  const handleCopyTicketId = () => {
    navigator.clipboard.writeText(ticketId);
    setOpens(true);

  };

  return (
    <div>
      <IconButton color="inherit" onClick={handleClickOpen}>
        <ReportProblemIcon />
      </IconButton>
      <Snackbar
  open={opens}
  autoHideDuration={6000}
  onClose={handleCloses}
  message="Copied to Clipboard"
  action={action}
  sx={{ zIndex: 9999 }}  // Adding zIndex to make sure it's on top
  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}

/>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>Create a Technical Problem Ticket</DialogTitle>
        <DialogContent>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            Please fill out the form below to report a technical issue. Attach a screenshot if possible.
          </Typography>
          <ThemeProvider theme={theme}>
          <form encType="multipart/form-data" onSubmit={handleSubmit}>
            <TextField
              autoFocus
              margin="dense"
              label="Name"
              type="text"
              fullWidth
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            <TextField
              margin="dense"
              label="Email"
              type="email"
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
                         <FormControl fullWidth margin="normal" variant="outlined">
             <TextField
                fullWidth
                multiline
                rows={4}              
                label="Describe the problem"
              //style={{ width: '100%', marginTop: '10px', padding: '10px' }}
              value={problem}
              onChange={(e) => setProblem(e.target.value)}
              required
            />
             </FormControl>
            <div style={{ marginTop: '15px', marginBottom: '15px' }}>
              <label htmlFor="screenshot-upload">
                <input
                  accept="image/*"
                  id="screenshot-upload"
                  type="file"
                  onChange={handleScreenshotChange}
                  style={{ display: 'none' }}
                />
                <Button variant="contained" component="span" 
                 startIcon={<CloudUploadIcon />}
                >
                  Upload Screenshot
                </Button>
                {screenshot && (
                  <Typography variant="body2" style={{ marginTop: '10px' }}>
                    {screenshot.name}
                  </Typography>
                )}
              </label>
            </div>
            <Button type="submit" color="primary" variant="contained" fullWidth>
              Submit
            </Button>
            {progress && (
              <Box sx={{ width: '100%', mt: 2 }}>
                <LinearProgress />
              </Box>
            )}
             {error && (
        <Typography color="error" variant="body2" sx={{ mt: 2 }}>
          {error}
        </Typography>
      )}
     

          </form>
         
          </ThemeProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* Confirmation Dialog */}
      <Dialog open={confirmationOpen} onClose={handleConfirmationClose} fullWidth maxWidth="xs">
        <DialogTitle>Ticket Created Successfully!</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Your Ticket ID is: <strong>{ticketId}</strong>
            <Tooltip title="Copy Ticket ID">
              <IconButton onClick={handleCopyTicketId}>
                <ContentCopyIcon />
              </IconButton>
            </Tooltip>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmationClose} color="primary" variant="contained">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CreateTicket;
