import React, { useState, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import './CountdownComponent.css';
import { styled } from '@mui/system';
import { Card } from 'react-bootstrap';

const StyledTitle = styled(Typography)(({ theme }) => ({
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontWeight: 'bold',
  fontSize: 'clamp(1.5rem, 5vw, 2rem)', // Larger font size for title
  color: 'transparent',
  background: 'linear-gradient(135deg, #fff 0%, #ccc 100%)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  letterSpacing: '0.05em',
  textTransform: 'uppercase',
  marginBottom: '15px',
  position: 'relative',
  display: 'inline-block',

  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '2px',
    background: 'linear-gradient(135deg, #fff 0%, #ccc 100%)',
    transform: 'scaleX(0)',
    transformOrigin: 'bottom right',
    transition: 'transform 0.3s ease-out',
  },

  '&:hover::after': {
    transform: 'scaleX(1)',
    transformOrigin: 'bottom left',
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#8B0000',
  borderRadius: '20px',
  padding: '10px 30px',
  fontSize: '1rem',
  textTransform: 'uppercase',
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)', // Subtle shadow
  transition: 'background-color 0.3s ease',

  '&:hover': {
    backgroundColor: '#6B0000',
  },
}));

const Overlay = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: '1000',
}));

const Modal = styled(Box)(({ theme }) => ({
  backgroundColor: '#fff',
  padding: '30px',
  boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.3)',
  borderRadius: '15px',
  position: 'relative',
  backgroundImage: `url("https://edugate-eg.com/wp-content/uploads/2024/03/171166430740415368-1.webp")`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  minHeight: '250px',
  width: '90vw',
  maxWidth: '500px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',

  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.4)', // Dark overlay for text readability
    zIndex: 1,
  },

  '& > *': {
    position: 'relative',
    zIndex: 2,
  },
}));

const StyledCloseIcon = styled(CloseIcon)(({ theme }) => ({
  position: 'absolute',
  top: '15px',
  right: '15px',
  cursor: 'pointer',
  color: '#fff',
  zIndex: 3,
}));

const SubscribeToNewsletter = () => {
  const [showSubscribe, setShowSubscribe] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [feedback, setFeedback] = useState({ message: '', type: '' });
  const [formData, setFormData] = useState({ email: '' });

  const theme = createTheme({
    palette: {
      primary: { main: "#8B0000" },
    },
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    if (!formData.email) {
      setFeedback({ message: "Please enter your email", type: 'error' });
      setIsSubmitting(false);
      return;
    }

    try {
      const response = await fetch('https://edutech-backend-471f7cb69116.herokuapp.com/api/newsletter', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'api-key': process.env.REACT_APP_API_KEY,
        },
        body: JSON.stringify(formData),
      });

      if (response.status === 201) {
        setFeedback({ message: "Subscribed successfully", type: 'success' });
        setTimeout(() => setShowSubscribe(false), 3000);
      } else {
        setFeedback({ message: "Error submitting the form", type: 'error' });
      }
    } catch (error) {
      setFeedback({ message: "Error submitting the form", type: 'error' });
    }
  };

  const handleClose = () => setShowSubscribe(false);

  return (
    <>
      {showSubscribe && (
        <Overlay>
          <Modal>
            <StyledCloseIcon onClick={handleClose} />
            <ThemeProvider theme={theme}>
            <Box sx={{ textAlign: 'center'}}>
              <StyledTitle>
                Subscribe to our newsletter!
              </StyledTitle>
              </Box>
              <form onSubmit={handleSubmit}>
                  <TextField
                    label="Enter your email"
                    variant="filled"
                    required
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    fullWidth
                    sx={{
                      backgroundColor: '#fff',
                    }}
                  />
                <StyledButton
                  variant="contained"
                  disabled={isSubmitting}
                  type="submit"
                  sx={{ marginTop: '20px', alignItems:"right" }}
                >
                  Subscribe
                </StyledButton>
              </form>
              {feedback.message && (
                <Typography
                  variant="body1"
                  sx={{ color: feedback.type === 'error' ? 'red' : 'green', marginTop: '10px' }}
                >
                  {feedback.message}
                </Typography>
              )}
            </ThemeProvider>
          </Modal>
        </Overlay>
      )}
    </>
  );
};

export default SubscribeToNewsletter;
