import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { FaFacebook, FaInstagram, FaLinkedin, FaYoutube, FaEnvelope, FaPhone } from "react-icons/fa";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import axios from 'axios';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import './ContactSectionComponent.css';
import ContactForm from './ContactForm';
import { styled } from '@mui/system';

// Styled Title for Simplicity and Elegance
const StyledTitle = ({ children }) => (
  <Typography 
    variant="h4" 
    style={{ 
      fontWeight: '600', 
      color: '#333', 
      letterSpacing: '1px', 
      marginBottom: '40px',
    }}
  >
    {children}
  </Typography>
);
const StyledTitle2 = styled(Typography)(({ theme }) => ({
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontWeight: 'bold',
  fontSize: '2.5rem', // Adjust size as needed
  color: 'transparent',
  background: 'linear-gradient(135deg, #C8102E 0%, #000000 100%)', // Gradient background
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  letterSpacing: '0.05em',
  textTransform: 'uppercase',
  position: 'relative',
  display: 'inline-block',
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '2px',
    backgroundColor: '#FF6347', // Accent color for underline
    transform: 'scaleX(0)',
    transformOrigin: 'bottom right',
    transition: 'transform 0.3s ease-out',
  },
  '&:hover::after': {
    transform: 'scaleX(1)',
    transformOrigin: 'bottom left',
  },
}));

function ContactSectionComponent() {
  const [contact, setContact] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiKey = process.env.REACT_APP_API_KEY;
        const response = await axios.get('https://edutech-backend-471f7cb69116.herokuapp.com/api/contact', {
          headers: {
            'Content-Type': 'application/json',
            'api-key': apiKey,
          },
        });
        setContact(response.data[0]);
      } catch (error) {
        console.error('Error fetching contact data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <section id="contact-section" className="contact-section">
      <Container>
        <Box sx={{ textAlign: 'center', mt: 4 }}>
          <StyledTitle2>Ready to Join Us</StyledTitle2>
        </Box>
        <Row className="gx-5 align-items-start">
          {/* Left Side: Contact Details */}
          <Col md={5} className="mb-4">
            <Card className="contact-card shadow-sm border-0 rounded">
              <Card.Body>
                <div className="contact-details">
                  <StyledTitle2 style={{  fontSize: "1.3rem"}}>Contact Information</StyledTitle2>

                  <p>
                    <FaEnvelope className="contact-icon" />
                    <a href={`mailto:${contact.email}`} className="contact-link">
                      {contact.email || 'Loading...'}
                    </a>
                  </p>
                  <p>
                    <LocationOnIcon className="contact-icon" />
                    <a href={contact.link} target="_blank" rel="noopener noreferrer" className="contact-link">
                      {contact.address || 'Loading...'}
                    </a>
                  </p>
                  <p>
                    <FaPhone className="contact-icon" />
                    <a href={`tel:${contact.phone}`} className="contact-link">
                      {contact.phone || 'Loading...'}
                    </a>
                  </p>
                </div>
                <div className="social-media mt-4">
                  <StyledTitle2 style={{  marginBottom:"2px",fontSize: "1.3rem"}}>Follow Us</StyledTitle2>

                  <div className="icon-container">
                    <a href={contact.facebook} target="_blank" rel="noopener noreferrer">
                      <FaFacebook className="social-icon" />
                    </a>
                    <a href={contact.instagram} target="_blank" rel="noopener noreferrer">
                      <FaInstagram className="social-icon" />
                    </a>
                    <a href={contact.linkedin} target="_blank" rel="noopener noreferrer">
                      <FaLinkedin className="social-icon" />
                    </a>
                    <a href={contact.youtube} target="_blank" rel="noopener noreferrer">
                      <FaYoutube className="social-icon" />
                    </a>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          
          {/* Right Side: Map and Contact Form */}
          <Col md={7}>
            <Card className="map-card shadow-sm border-0 rounded mb-4">
              <Card.Body>
                <div className="map-container">
                  <iframe
                    title="Google Maps"
                    src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3453.9047066681255!2d31.230556075653567!3d30.03959161880045!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzDCsDAyJzIyLjUiTiAzMcKwMTMnNTkuMyJF!5e0!3m2!1sen!2seg!4v1710014290514!5m2!1sen!2seg"
                    width="100%"
                    height="300"
                    frameBorder="0"
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    style={{ borderRadius: '10px', border: '1px solid #ddd' }}
                  ></iframe>
                </div>
              </Card.Body>
            </Card>
                <ContactForm />
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default ContactSectionComponent;
