import React, { useState, useEffect } from 'react';
import VideoCard from './VideoCard'; // Import the VideoCard component
import './VideoSection.css'; // Import your custom styles
import axios from 'axios'; // Import Axios
import Select from '@mui/material/Select'; // Import the Select component
import MenuItem from '@mui/material/MenuItem'; // Import the MenuItem component
import FormControl from '@mui/material/FormControl'; // Import the FormControl component
import InputLabel from '@mui/material/InputLabel'; // Import the InputLabel component
import Grid from '@mui/material/Grid'; // Import the Grid component
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const StyledTitle = styled(Typography)(({ theme }) => ({
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontWeight: 'bold',
  fontSize: '2.5rem', // Adjust size as needed
  color: 'transparent',
  background: 'linear-gradient(135deg, #C8102E 0%, #000000 100%)', // Gradient background
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  letterSpacing: '0.05em',
  textTransform: 'uppercase',
  position: 'relative',
  display: 'inline-block',
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '3px',
    backgroundColor: '#FF6347', // Accent color for underline
    transform: 'scaleX(0)',
    transformOrigin: 'bottom right',
    transition: 'transform 0.4s ease-out',
  },
  '&:hover::after': {
    transform: 'scaleX(1)',
    transformOrigin: 'bottom left',
  },
}));

const VideoSection = () => {
  const [selectedYear, setSelectedYear] = useState('');
  const [selectedTitle, setSelectedTitle] = useState('');
  const [videoData, setVideoData] = useState([]); // State to store fetched video data
  const [uniqueYears, setUniqueYears] = useState([]); // State to store unique years

  const theme = createTheme({
    palette: {
      primary: {
        main: "#8B0000"
      },
  
    }
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiKey = process.env.REACT_APP_API_KEY; // Load API key from environment variable
        const response = await axios.get('https://edutech-backend-471f7cb69116.herokuapp.com/api/videoData', {
          headers: {
            'Content-Type': 'application/json',
            'api-key': apiKey, // Include the API key in the request headers
          },
        });
        setVideoData(response.data); // Set the video data in state
        
        // Extract unique years
        const years = [...new Set(response.data.map(video => video.year))];
        setUniqueYears(years);
        const maxYear = Math.max(...years);
        setSelectedYear(maxYear.toString());

      } catch (error) {
        console.error('Error fetching video data:', error);
      }
    };
  
    fetchData();
  }, []);
  

  const getUniqueTitles = (year) => {
    if (year === '') {
      return [...new Set(videoData.map(video => video.title))];
    } else {
      return [...new Set(videoData.filter(video => video.year === year).map(video => video.title))];
    }
  };

  const filteredVideos = videoData.filter(video => {
    // Check if the video matches the selected year and title
    const yearMatch = selectedYear === '' || video.year === selectedYear;
    const titleMatch = selectedTitle === '' || video.title === selectedTitle;
    
    return yearMatch && titleMatch;
  });

  return (
    <section className="video-section">
      <div className="section-title-container">
        <Box>
        <StyledTitle variant="h5" gutterBottom>
        Video Sessions</StyledTitle>
        </Box>
      </div>

      <div style={{ margin: "20px auto", maxWidth: "800px" }}>
        <Grid container spacing={3}>
          <ThemeProvider theme={theme}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="year-label">Year</InputLabel>
                <Select
                  labelId="year-label"
                  id="year-select"
                  value={selectedYear}
                  label="Year"
                  onChange={(event) => setSelectedYear(event.target.value)}
                  className="custom-select"
                >
                  <MenuItem value="">All Years</MenuItem>
                  {uniqueYears.map(year => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </ThemeProvider>

          <ThemeProvider theme={theme}>
  <Grid item xs={12} sm={6}>
    <FormControl fullWidth variant="outlined">
      <InputLabel id="title-label">Title</InputLabel>
      <Select
        labelId="title-label"
        id="title-select"
        value={selectedTitle}
        label="Title"
        onChange={(event) => setSelectedTitle(event.target.value)}
        className="custom-select"
        autoWidth={true}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 300,  // Max height of dropdown for vertical scrolling
              whiteSpace: 'normal',  // Allow text to wrap if too long
            },
          },
          MenuListProps: {
            style: {
              overflowX: 'auto',  // Horizontal scroll for long text
              overflowY: 'auto',  // Vertical scroll for multiple items
            },
          },
        }}
      >
        <MenuItem value="">All Titles</MenuItem>
        {getUniqueTitles(selectedYear).map((title) => (
          <MenuItem key={title} value={title}>
            {title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </Grid>
</ThemeProvider>

        </Grid>
      </div>
      <div style={{ margin: "20px auto", maxWidth: "1200px" }} className="video-list">
        {filteredVideos.map(video => (
          <VideoCard key={video.id} video={video} />
        ))}
      </div>
    </section>
  );
};

export default VideoSection;
