import React, { useState, useEffect } from 'react';
import Countdown from 'react-countdown';
import './CountdownComponent.css';
import { AddToCalendarButton } from 'add-to-calendar-button-react';
import axios from 'axios';
import { FaShare } from "react-icons/fa";
import CircularProgress from '@mui/material/CircularProgress';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { FaDownload, FaWhatsapp } from "react-icons/fa";

import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import Box from '@mui/material/Box';
const StyledTitle2 = styled(Typography)(({ theme }) => ({
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontWeight: 'bold',
  fontSize: 'clamp(1rem, 4vw, 2.5rem)', // Responsive font size
  color: 'transparent', // Text color set to transparent so gradient shows
  background: 'linear-gradient(135deg, #FFFFFF 0%, #E0E0E0 100%)', // White gradient background for the text
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  letterSpacing: '0.05em',
  textTransform: 'uppercase',
  position: 'relative',
  display: 'inline-block',
  
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '2px',
    background: 'linear-gradient(135deg, #FFFFFF 0%, #E0E0E0 100%)', // Gradient white underline
    transform: 'scaleX(0)',
    transformOrigin: 'bottom right',
    transition: 'transform 0.3s ease-out',
  },

  '&:hover::after': {
    transform: 'scaleX(1)', // Underline expands on hover
    transformOrigin: 'bottom left',
  },
}));
const CountdownComponent = () => {
  const [eventDate, setEventDate] = useState(null);
  const [eventName, setEventName] = useState('');
  const [eventStartDate, setEventStartDate] = useState('');

  const theme = createTheme({
    palette: {
      primary: {
        main: "#8B0000"
      },
  
    }
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiKey = process.env.REACT_APP_API_KEY; // Load API key from environment variable
        const response = await axios.get('https://edutech-backend-471f7cb69116.herokuapp.com/api/figures', {
          headers: {
            'Content-Type': 'application/json',
            'api-key': apiKey, // Include the API key in the request headers
          },
        });
        const firstRecord = response.data[0];
        const dateParts = firstRecord.date.split('-'); // Assuming format is YYYY-MM-DD
        if (dateParts.length === 3) {
          const year = parseInt(dateParts[0]);
          const month = parseInt(dateParts[1]); // Month is 0-based
          const day = parseInt(dateParts[2]);
          const middle = year + "-" + "0" + month + "-" + "0" + day; //+ "T10:00:00";
          const eventDate = new Date(middle).getTime();
          setEventDate(eventDate);
        }
        setEventName(firstRecord.name);
        setEventStartDate(firstRecord.startDate);
      } catch (error) {
        console.error('Error fetching collection:', error);
      }
    };
  
    fetchData();
  }, []);
  

  const renderer = ({ days, hours, minutes, seconds }) => {
    const formatNumber = (number) => {
      return number < 10 ? `0${number}` : number;
    };

    

    return (
      <div className="countdown-container">
  <div className="background-image-container">
    <img
      src="https://edugate-eg.com/wp-content/uploads/2023/09/1024-scaled.webp"
      alt="Event Background"
      className="background-image"
    />
  </div>
  <div className="countdown-overlay">
    <div className="countdown-card">
      <Box sx={{ textAlign: 'center', mt: 4 }}>
        <StyledTitle2 variant="h5" gutterBottom>
          Upcoming Event - Stay Tuned!
        </StyledTitle2>
      </Box>
      <div className="countdown">
  <div className="countdown-item">
    <div className={`countdown-circle ${days === 0 ? 'flipped' : ''}`}>
      {formatNumber(days)}
    </div>
    <span>Days</span>
  </div>
  <div className="countdown-item">
    <div className={`countdown-circle ${hours === 0 ? 'flipped' : ''}`}>
      {formatNumber(hours)}
    </div>
    <span>Hours</span>
  </div>
  <div className="countdown-item">
    <div className={`countdown-circle ${minutes === 0 ? 'flipped' : ''}`}>
      {formatNumber(minutes)}
    </div>
    <span>Minutes</span>
  </div>
  <div className="countdown-item">
    <div className={`countdown-circle ${seconds === 0 ? 'flipped' : ''}`}>
      {formatNumber(seconds)}
    </div>
    <span>Seconds</span>
  </div>
</div>


      <div className="countdown-buttons">
        <ThemeProvider theme={theme}>
          <div style={{ marginBottom: '10px' }}>
            <a style={{ textDecoration: 'none' }} href="/RegisterPage">
              <Button className="button" variant="contained">Register Now!</Button>
            </a>
            <a
              style={{ margin: "10px" }}
              href={`https://api.whatsapp.com/send?text=Join%20us%20for%20the%20${encodeURIComponent(eventName)}%20on%20${encodeURIComponent(eventStartDate)}%20More%20details%20here:%20https://edutech-eg.com/`}
              target="_blank"
            >
              <Button className="button" variant="contained">
                Share Event <FaWhatsapp style={{ color: 'white', marginLeft: '8px' }} />
              </Button>
            </a>
          </div>

          <div>
            <AddToCalendarButton
              name={eventName} 
              startDate={eventStartDate} // Use the fetched event start date
              options={['Apple', 'Google']}
              trigger="click"
              inline
              listStyle="modal"
            />
          </div>
        </ThemeProvider>
      </div>
    </div>
  </div>
</div>

    );
  };

  return eventDate !== null ? (
    <Countdown date={eventDate} renderer={renderer} />
  ) : (
    <div>     
        <ThemeProvider theme={theme}>

       <CircularProgress  />
       </ThemeProvider>

    </div> // You can replace this with a loading spinner or message
  );
};

export default CountdownComponent;
