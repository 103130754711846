import React, { useState } from 'react';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import './VideoImageSection.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Modal from '@mui/material/Modal'; // Import the Modal component
import Box from '@mui/material/Box';
import GetAppIcon from '@mui/icons-material/GetApp'; // Import the GetAppIcon for download
import { styled } from '@mui/system';

const StyledTitle = styled(Typography)(({ theme }) => ({
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontWeight: 'bold',
  fontSize: '2.5rem', // Adjust size as needed
  color: 'transparent',
  background: 'linear-gradient(135deg, #C8102E 0%, #000000 100%)', // Gradient background
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  letterSpacing: '0.05em',
  textTransform: 'uppercase',
  position: 'relative',
  display: 'inline-block',
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '2px',
    backgroundColor: '#FF6347', // Accent color for underline
    transform: 'scaleX(0)',
    transformOrigin: 'bottom right',
    transition: 'transform 0.3s ease-out',
  },
  '&:hover::after': {
    transform: 'scaleX(1)',
    transformOrigin: 'bottom left',
  },
}));
const yearsData = {
  2022: {
    pdfUrl:"https://edugate-eg.com/wp-content/uploads/2023/09/Post-show-report-TECH.pdf",
    videoUrl: 'https://www.youtube.com/embed/z_ZSYYgnAHA?si=1h29iThik3BJ4Wdm',
    description: 'This is a short description of the 2022 event.',
    images: [
      'https://edugate-eg.com/wp-content/uploads/2023/09/forum-2-scaled.webp',
      'https://edugate-eg.com/wp-content/uploads/2023/09/GIZ-1-scaled.webp',
      'https://edugate-eg.com/wp-content/uploads/2023/09/Registration-Area-2-scaled.webp',
      'https://edugate-eg.com/wp-content/uploads/2023/09/STA-El-Sewedy-Technical-Academy-3-scaled.webp',
      'https://edugate-eg.com/wp-content/uploads/2023/09/STA-El-Sewedy-Technical-Academy-1-scaled.webp',
      'https://edugate-eg.com/wp-content/uploads/2023/09/forum-8-scaled.webp',
      'https://edugate-eg.com/wp-content/uploads/2023/09/1024-1-scaled.webp',
      'https://edugate-eg.com/wp-content/uploads/2023/09/STA-El-Sewedy-Technical-Academy-2-scaled.webp',
      'https://edugate-eg.com/wp-content/uploads/2023/09/WE-partner-scaled.webp',
      'https://edugate-eg.com/wp-content/uploads/2023/09/forum-10-scaled.webp',
      'https://edugate-eg.com/wp-content/uploads/2023/09/481-scaled.webp',
      'https://edugate-eg.com/wp-content/uploads/2023/09/STA-El-Sewedy-Technical-Academy-4-scaled.webp',
      'https://edugate-eg.com/wp-content/uploads/2023/09/forum-1-scaled.webp',
      'https://edugate-eg.com/wp-content/uploads/2023/09/جولة-المسؤوليين-3-scaled.webp',
      'https://edugate-eg.com/wp-content/uploads/2023/09/giz-partner-scaled.webp',
      'https://edugate-eg.com/wp-content/uploads/2023/09/forum-3-scaled.webp',
      'https://edugate-eg.com/wp-content/uploads/2023/09/forum-5-scaled.webp',
      'https://edugate-eg.com/wp-content/uploads/2023/09/5140-scaled.webp',
      'https://edugate-eg.com/wp-content/uploads/2023/09/305-scaled.webp',
      'https://edugate-eg.com/wp-content/uploads/2023/09/1068-scaled.webp',
      'https://edugate-eg.com/wp-content/uploads/2023/09/1124-scaled.webp',
      'https://edugate-eg.com/wp-content/uploads/2023/09/1049-scaled.webp',
      'https://edugate-eg.com/wp-content/uploads/2023/09/1203-scaled.webp',
      'https://edugate-eg.com/wp-content/uploads/2023/09/1262-scaled.webp',
      'https://edugate-eg.com/wp-content/uploads/2023/09/1555-scaled.webp',
      'https://edugate-eg.com/wp-content/uploads/2023/09/NASS-Academy-sponsor-scaled.webp',
      'https://edugate-eg.com/wp-content/uploads/2023/09/1241-scaled.webp',
      'https://edugate-eg.com/wp-content/uploads/2023/09/forum-scaled.webp',
      'https://edugate-eg.com/wp-content/uploads/2023/09/1747-scaled.webp',
      'https://edugate-eg.com/wp-content/uploads/2023/09/forum-16-scaled.webp',
      'https://edugate-eg.com/wp-content/uploads/2023/09/TVET-partner-2-scaled.webp',
      'https://edugate-eg.com/wp-content/uploads/2023/09/forum-12-scaled.webp',
      'https://edugate-eg.com/wp-content/uploads/2023/09/forum-17-scaled.webp',
      'https://edugate-eg.com/wp-content/uploads/2023/09/TVET-partner-scaled.webp',
      'https://edugate-eg.com/wp-content/uploads/2023/09/forum-18-scaled.webp',
      'https://edugate-eg.com/wp-content/uploads/2023/09/1586-scaled.webp',
    ],
  },
  2023: {
    pdfUrl:"https://edugate-eg.com/wp-content/uploads/2023/09/EduTech-Egypt-2023-Post-show-report.pdf",
    videoUrl: 'https://www.youtube.com/embed/O3hQez76bTU?si=sQfI9tV8V85K5VaF',
    description: 'This is a short description of the 2023 event.',
    images: [
      'https://edugate-eg.com/wp-content/uploads/2023/09/10A9390-scaled.webp',
      'https://edugate-eg.com/wp-content/uploads/2023/09/10A9522-scaled.webp',
      'https://edugate-eg.com/wp-content/uploads/2023/09/I0A8957-scaled.webp',
      'https://edugate-eg.com/wp-content/uploads/2023/09/10A9316-scaled.webp',
      'https://edugate-eg.com/wp-content/uploads/2023/09/10A9515-scaled.webp',
      'https://edugate-eg.com/wp-content/uploads/2023/09/10A9287-scaled.webp',
      'https://edugate-eg.com/wp-content/uploads/2023/09/10A9124-scaled.webp',
      'https://edugate-eg.com/wp-content/uploads/2023/09/E03A7049-scaled.webp',
      'https://edugate-eg.com/wp-content/uploads/2023/09/10A9467-scaled.webp',
      'https://edugate-eg.com/wp-content/uploads/2023/09/10A9776-scaled.webp',
      'https://edugate-eg.com/wp-content/uploads/2023/09/E03A6680-scaled.webp',
      'https://edugate-eg.com/wp-content/uploads/2023/09/E03A6897-scaled.webp',
      'https://edugate-eg.com/wp-content/uploads/2023/09/E03A6708-scaled.webp',
      'https://edugate-eg.com/wp-content/uploads/2023/09/E03A6758-copy-scaled.webp',
      'https://edugate-eg.com/wp-content/uploads/2023/09/10A9178-copy-scaled.webp',
      'https://edugate-eg.com/wp-content/uploads/2023/09/I0A8660-scaled.webp',
      'https://edugate-eg.com/wp-content/uploads/2023/09/I0A8616-scaled.webp',
      'https://edugate-eg.com/wp-content/uploads/2023/09/10A9064-copy-scaled.webp',
      'https://edugate-eg.com/wp-content/uploads/2023/09/E03A7482-scaled.webp',
      'https://edugate-eg.com/wp-content/uploads/2023/09/10A9048-copy-scaled.webp',
      'https://edugate-eg.com/wp-content/uploads/2023/09/E03A6645-scaled.webp',
      'https://edugate-eg.com/wp-content/uploads/2023/09/E03A6786-scaled.webp',
      'https://edugate-eg.com/wp-content/uploads/2023/09/E03A6501-scaled.webp',
      'https://edugate-eg.com/wp-content/uploads/2023/09/10A9019-copy-scaled.webp',
      'https://edugate-eg.com/wp-content/uploads/2023/09/10A9896-scaled.webp',
      'https://edugate-eg.com/wp-content/uploads/2023/09/I0A8597-scaled.webp',
      'https://edugate-eg.com/wp-content/uploads/2023/09/10A9035-copy-scaled.webp',
      'https://edugate-eg.com/wp-content/uploads/2023/09/10A9465-scaled.webp',
      'https://edugate-eg.com/wp-content/uploads/2023/09/10A9791-scaled.webp',

    ],
  },
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#8B0000"
    },

  }
});

const PastEvents = () => {
  const [value, setValue] = useState(2023);
  const [open, setOpen] = useState(false); // State for the modal
  const [selectedImage, setSelectedImage] = useState(null); // State to track the selected image

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleDownloadReport = () => {
    alert(`Downloading report for ${value}`);
  };

  const yearData = yearsData[value];
  const handleOpen = (image) => {
    setSelectedImage(image);
    setOpen(true); // Open the modal
  };

  const handleClose = () => {
    setSelectedImage(null);
    setOpen(false); // Close the modal
  };

  return (
    <div className="past-events-container">
      <div className="section-title-container">
      <Box sx={{ textAlign: 'center', mt: 4 }}>

<StyledTitle variant="h5" gutterBottom>
Past events
</StyledTitle>
</Box>
      </div>

      <Paper elevation={3} className="tab-container">
      <ThemeProvider theme={theme}>

        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label="2022" value={2022} className="tab-button" />
          <Tab label="2023" value={2023} className="tab-button" />
        </Tabs>
        </ThemeProvider>
      </Paper>

      {yearData && (
        <div className="event-details">
          <div className="video-container">
            <iframe
              title={`Event Video ${value}`}
              className="event-video"
              src={yearData.videoUrl}
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </div>
          <div className="event-content">
            <ThemeProvider theme={theme}>

            <a style={{ textDecoration: 'none' }} href={yearData.pdfUrl} target="_blank" rel="noopener noreferrer">
          <Button variant="contained" className="download-button">
             Download {value} Report < GetAppIcon style={{ color: 'white', marginLeft: '8px' }} />
          </Button>
        </a>
            </ThemeProvider>
          </div>
          <ImageList className="event-image-list" cols={4} rowHeight={180}>
            {yearData.images.map((imageUrl, index) => (
              <ImageListItem key={index} className="event-image-item">
                <img
                  src={imageUrl}
                  alt={`Event Image ${value}-${index}`}
                  loading="lazy"
                  style={{
                    objectFit: 'cover',
                    width: '100%',
                    height: '100%',
                    cursor: 'pointer', // Add pointer cursor for image
                  }}
                  onClick={() => handleOpen(imageUrl)} // Open modal on image click
                />
              </ImageListItem>
            ))}
          </ImageList>
        </div>
      )}

      {/* Modal for image magnification */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            width: '90vw',
            maxWidth: '800px', // Adjust the max width as needed
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 2,
          }}
        >
          <img
            src={selectedImage}
            alt="Magnified Image"
            style={{ width: '100%', height: 'auto', maxHeight: '70vh', objectFit: 'contain' }}
          />
        </Box>
      </Modal>
    </div>
  );
};

export default  PastEvents ;
  ;